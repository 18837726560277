import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  Button,
  Loading,
  Modal
} from "carbon-components-react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/src/styles.scss";
import "./details.scss";
import Header from "../components/Header/Header";
import axios from "axios";
import { getNotification, getSize } from "../lib/util";
import { BACKEND_ENDPOINT } from "../lib/constants";

function Recording() {
  const [src, setSrc] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasTranscript, setHasTranscript] = useState(false);
  const [hasSummary, setHasSummary] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const url = new URL(decodeURIComponent(window.location.href));
  const id = url.searchParams.get("id");
  const bytes = url.searchParams.get("size");
  let parts = id.split(".");
  let transcriptFileName = parts[0].replace("recording", "transcript") + ".txt";
  let summaryFileName = parts[0].replace("recording", "summary") + ".txt";
  const [texts, setTexts] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_ENDPOINT}/get-file-url/${id}`
      );
      setSrc(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setNotifications([
        ...notifications,
        getNotification("error", "Error fetching data: " + error.toString()),
      ]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const download = () => {
  let pom = document.createElement("a");
  pom.href = src;
  pom.setAttribute("download", id);
  pom.click();
  };

  const transcribe = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${BACKEND_ENDPOINT}/get-transcript-cos/${id}/${transcriptFileName}`
    );
    if (response.data) {
      let newTexts = [];
      newTexts.push({
        id: transcriptFileName,
        text: response.data,
        open: true,
      });
      setTexts(newTexts);
      setIsLoading(false);
      setHasTranscript(true);
    }
  };

  const summarize = async () => {
    setIsLoading(true);
    const config = {
      headers: { "content-type": `application/json` },
    };
    const data = {
      text: texts[0].text,
    };
    const response = await axios.post(
      `${BACKEND_ENDPOINT}/get-summary-cos/${id}/${summaryFileName}`,
      data,
      config
    );
    if (response.data) {
      let newTexts = [...texts];
      newTexts[0].open = false;
      newTexts.push({ id: summaryFileName, text: response.data, open: true });
      setTexts(newTexts);
      setIsLoading(false);
      setHasSummary(true);
    }
  };

  const deleteFile = async() => {
    setOpenDelete(false)
    try {
      const config = {
        headers: { "content-type": `application/json` },
      };
      const data = {
        keys: [{"Key": id}],
      };
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/delete-files`,
        data,
        config
      );
      if (response.status === 200) {
        setNotifications([
          ...notifications,
          getNotification("success", "Successfully deleted file."),
        ]);
        window.location.href = "/"
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNotifications([
        ...notifications,
        getNotification("error", "Error deleting file: " + error.toString()),
      ]);
    }
  }

  return (
    <>
      <Header />
      {notifications}
      <Modal
        modalHeading="Delete a file"
        modalLabel=""
        danger={true}
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        open={openDelete}
        onRequestClose={() => setOpenDelete(false)}
        onSecondarySubmit={() => setOpenDelete(false)}
        onRequestSubmit={deleteFile}
      >
        Are you sure you want to delete this file?
      </Modal>
      <div className="containerHome displayFlex">
        <div className="leftSide">
          <p className="recordingFont">Recording</p>
          <div className="fileName">{id}</div>
          <div className="fileSize">Audio Recording - {getSize(bytes)}</div>
          <AudioPlayer src={src} autoPlayAfterSrcChange={false} />
        </div>
        <div className="rightSide">
          <Accordion size="lg">
            {texts.map((i) => (
              <AccordionItem
                title={
                  <div className="displayFlex">
                    <div className="accordionIcon">
                      <img
                        src={
                          i.id?.includes("transcript")
                            ? "/img/icon_transcript.png"
                            : "/img/icon_summary.png"
                        }
                        alt="back"
                      />
                    </div>
                    <p className="accordionHead">{i.id}</p>
                  </div>
                }
                key={i.id}
                open={i.open}
                onHeadingClick={(event) => console.log(event)}
              >
                <p className="accordionItem">{i.text}</p>
              </AccordionItem>
            ))}{" "}
          </Accordion>
          {isLoading && (
            <>
              <Loading className="center loading" withOverlay={false} />
              <p className="recordingText">
                {hasTranscript ? "Summarization" : "Transcription"} in
                progress...
              </p>
            </>
          )}
        </div>
        <div className="pageFooter">
          <img
            onClick={() => (window.location.href = "/")}
            className="backIcon"
            width="1.5%"
            height="1.5%"
            src="/img/icon_arror_back.png"
            alt="back"
          />
          <p
            className="back hyper"
            onClick={() => (window.location.href = "/")}
          >
            Back
          </p>
          <div className="displayFlex smallGap buttons">
            <Button
              size="sm"
              kind="primary"
              onClick={hasTranscript ? summarize : transcribe}
              disabled={hasTranscript && hasSummary}
            >
              {hasTranscript ? "Summarize" : "Transcribe"}
            </Button>
            <Button size="sm" kind="secondary" onClick={download}>
              Download
            </Button>
            <Button size="sm" kind="tertiary">
              Share
            </Button>
            <Button size="sm" kind="danger" onClick={() => setOpenDelete(true)}>
              Delete
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Recording;
