/*
 * Copyright 2020 IBM Corp. All Rights Reserved
 * IBM Confidential Source Code Materials
 * This Source Code is subject to the license and security terms contained in the license/LICENSE file contained in this source code package.
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CardTable.scss";
import { Pagination, Button, Checkbox, Modal } from "carbon-components-react";
import FileCard from "../DocumentCard/FileCard";
import { getNotification } from "../../lib/util";
import { BACKEND_ENDPOINT } from "../../lib/constants";

const CardTable = (props) => {
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const PAGE_SIZES = [10, 20, 30];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setselectedItems([]);
    }
  }, [props.data]);

  const handleChildTableClick = (event, id) => {
    event.stopPropagation();
    window.location.href =
      props.type === "recording"
        ? `/recording?id=${id}&size=${data.find((i) => i.id === id)?.size}`
        : `/text?id=${id}&type=${props.type}`;
  };

  const onClickCheckbox = (event, id) => {
    if (!selectedItems.find((i) => i.id === id)) {
      console.log(data);
      console.log(data.find((i) => i.id === id));
      console.log(selectedItems);
      let a = [...selectedItems, data.find((i) => i.id === id)];
      setselectedItems(a);
    } else {
      let b = selectedItems.filter((i) => i.id !== id);
      setselectedItems(b);
    }
    event.stopPropagation();
  };

  const handlePageChange = (event) => {
    setPageSize(event.pageSize);
    if (props.setPage) {
      props.setPage(event.page);
    }
    setPage(event.page);
  };

  useEffect(() => {
    if (props.page) {
      setPage(props.page);
    }
  }, [props.page]);

  const selectRowsByPage = (rows) => {
    return rows?.filter(
      (_, index) =>
        index >= pageSize * (page - 1) && index <= pageSize * page - 1
    );
  };

  function renderDocumentCards() {
    return selectRowsByPage(data).map((x) => {
      let id = x.id;
      return (
        <div
          key={id}
          id={id}
          onClick={(event) => handleChildTableClick(event, id)}
          className="relative"
        >
          {props.showCheckbox && (
            <div
              className="checkbox"
              onClick={(event) => onClickCheckbox(event, id)}
            >
              <Checkbox
                id={id}
                labelText=""
                checked={Boolean(selectedItems.find((i) => i.id === id))}
              />
            </div>
          )}
          <FileCard
            className={"result " + (id === selectedId ? "selected" : "")}
            showCheckbox={props.showCheckbox}
            {...x}
          />
        </div>
      );
    });
  }

  const deleteFile = async () => {
    setOpenDelete(false);
    try {
      const config = {
        headers: { "content-type": `application/json` },
      };
      const data = {
        keys: [],
      };
      selectedItems.forEach((i) => data.keys.push({ Key: i.id }));
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/delete-files`,
        data,
        config
      );
      if (response.status === 200) {
        setNotifications([
          ...notifications,
          getNotification("success", "Successfully deleted file."),
        ]);
        await props.refetch();
        setselectedItems([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNotifications([
        ...notifications,
        getNotification("error", "Error deleting file: " + error.toString()),
      ]);
    }
  };

  return (
    <div className={"relative " + props.className}>
      {notifications}
      <Modal
        modalHeading="Delete a file"
        modalLabel=""
        danger={true}
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        open={openDelete}
        onRequestClose={() => setOpenDelete(false)}
        onSecondarySubmit={() => setOpenDelete(false)}
        onRequestSubmit={deleteFile}
      >
        Are you sure you want to delete this file?
      </Modal>
      {selectedItems.length > 0 && (
        <div className="addButton displayFlex smallGap">
          <Button size="sm" kind="primary">
            Download
          </Button>
          <Button size="sm" kind="secondary">
            Share
          </Button>
          <Button size="sm" kind="danger" onClick={() => setOpenDelete(true)}>
            Delete
          </Button>
        </div>
      )}
      <div className="results">
        {data.length > 0 && (
          <>
            <div className={props.headerClasses}>{props.header}</div>
            {renderDocumentCards()}
          </>
        )}
      </div>
      {data.length > 0 && (
        <Pagination
          pageSizes={PAGE_SIZES}
          page={props.page || page}
          totalItems={data.length}
          onChange={handlePageChange}
          className={props.isChild ? "" : "white"}
        />
      )}
    </div>
  );
};

export default CardTable;
