import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  Button,
  Loading,
  Modal
} from "carbon-components-react";
import "react-h5-audio-player/src/styles.scss";
import "./details.scss";
import Header from "../components/Header/Header";
import axios from "axios";
import { getNotification, getSize } from "../lib/util";
import { BACKEND_ENDPOINT } from "../lib/constants";

function Text() {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [recordingFile, setRecordingFile] = useState(null);
  const [otherFile, setOtherFile] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id");
  const type = url.searchParams.get("type");
  let storedUser = window.localStorage.getItem("meetakerUser");
  let email = storedUser.replaceAll("@", "-").replaceAll(".", "-");

  const [texts, setTexts] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_ENDPOINT}/get-file-content/${id}`
      );
      let uuid = id.split(type)[1].split(".")[0];
      const config = {
        headers: { "content-type": `application/json` },
      };
      const data = {
        email,
        uuid,
      };
      const res = await axios.post(
        `${BACKEND_ENDPOINT}/get-files`,
        data,
        config
      );
      const items = res.data?.data || [];
      items.forEach((i) => {
        let date = new Date(i.createdAt);
        i.date = date.toLocaleDateString();
        i.time = date.toLocaleTimeString();
      });
      setFiles(items);
      setTexts([
        {
          id: id,
          text: response.data,
          open: true,
        },
      ]);
      setIsLoading(false);
      setRecordingFile(items.find((i) => i.id.includes("recording")));
      setOtherFile(
        items.find((i) => !i.id.includes("recording") && !i.id.includes(type))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setNotifications([
        ...notifications,
        getNotification("error", "Error fetching data: " + error.toString()),
      ]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteFile = async() => {
    setOpenDelete(false)
    try {
      const config = {
        headers: { "content-type": `application/json` },
      };
      const data = {
        keys: [{"Key": id}],
      };
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/delete-files`,
        data,
        config
      );
      if (response.status === 200) {
        setNotifications([
          ...notifications,
          getNotification("success", "Successfully deleted file."),
        ]);
        window.location.href = "/"
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNotifications([
        ...notifications,
        getNotification("error", "Error deleting file: " + error.toString()),
      ]);
    }
  }

  const download = async() => {
    const response = await axios.get(
      `${BACKEND_ENDPOINT}/get-file-url/${id}`
    );
    let pom = document.createElement("a");
    pom.href = response.data;
    pom.setAttribute("download", id);
    pom.click();
  };


  return (
    <>
      <Header />
      {notifications}
      <Modal
        modalHeading="Delete a file"
        modalLabel=""
        danger={true}
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        open={openDelete}
        onRequestClose={() => setOpenDelete(false)}
        onSecondarySubmit={() => setOpenDelete(false)}
        onRequestSubmit={deleteFile}
      >
        Are you sure you want to delete this file?
      </Modal>
      <div className="containerHome displayFlex">
        <div className="leftSide">
          <p className="recordingFont">
            {type === "transcript" ? "Transcription" : "Summary"}
          </p>
          <div className="fileName">{id}</div>
          <div className="dateMargin">
            {type === "transcript" ? "Transcribed:" : "Summarized:"}{" "}
            {files.find((i) => i.id.includes(type))?.date},{" "}
            {files.find((i) => i.id.includes(type))?.time}
          </div>

          {otherFile && (
            <>
              <p className="subFont">
                {type === "transcript" ? "Summary" : "Transcript"}
              </p>
              <div className="fileName">
                <a
                  href={`/text?id=${otherFile.id}&type=${type === "transcript" ? "summary" : "transcript"}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {otherFile.id}
                </a>
              </div>
              <div className="dateMargin">
                {type === "transcript" ? "Summarized:" : "Transcribed:"}{" "}
                {otherFile.date}, {otherFile.time}
              </div>
            </>
          )}

          {recordingFile && (
            <>
              <p className="subFont">Recording</p>
              <div className="fileName">
                <a
                  href={`/recording?id=${recordingFile.id}&size=${recordingFile.size}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {recordingFile.id}
                </a>
              </div>
              <div className="fileSize">
                Recorded: {recordingFile.date}, {recordingFile.time}
              </div>
              <div className="fileSize">
                Audio Recording - {getSize(recordingFile.size)}
              </div>
            </>
          )}
        </div>
        <div className="rightSide">
          <Accordion size="lg">
            {texts.map((i) => (
              <AccordionItem
                title={
                  <div className="displayFlex">
                    <div className="accordionIcon">
                      <img
                        src={
                          i.id?.includes("transcript")
                            ? "/img/icon_transcript.png"
                            : "/img/icon_summary.png"
                        }
                        alt="back"
                      />
                    </div>
                    <p className="accordionHead">{i.id}</p>
                  </div>
                }
                key={i.id}
                open={i.open}
                onHeadingClick={(event) => console.log(event)}
              >
                <p className="accordionItem">{i.text}</p>
              </AccordionItem>
            ))}{" "}
          </Accordion>
          {isLoading && (
            <>
              <Loading className="center loading" withOverlay={false} />
              <p className="recordingText">Fetching data...</p>
            </>
          )}
        </div>
        <div className="pageFooter">
          <img
            onClick={() => (window.location.href = "/")}
            className="backIcon"
            width="1.5%"
            height="1.5%"
            src="/img/icon_arror_back.png"
            alt="back"
          />
          <p
            className="back hyper"
            onClick={() => (window.location.href = "/")}
          >
            Back
          </p>
          <div className="displayFlex smallGap buttons">
            <Button size="sm" kind="primary" onClick={download}>
              Download
            </Button>
            <Button
              size="sm"
              kind="secondary"
              onClick={() => (window.location.href = "/")}
            >
              Share
            </Button>
            <Button size="sm" kind="danger" onClick={() => setOpenDelete(true)}>
              Delete
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Text;
