import { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Loading,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Modal,
  TextInput,
} from "carbon-components-react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { v4 as uuidv4 } from "uuid";
import { Play, Stop, Add } from "@carbon/icons-react";
import Header from "../components/Header/Header";
import "./home.scss";
import CardTable from "../components/CardTable/CardTable";
import axios from "axios";
import { getNotification } from "../lib/util";
import { BACKEND_ENDPOINT } from "../lib/constants";

function Home() {
  const platforms = [
    { id: "teams", name: "Microsoft Teams" },
    { id: "google", name: "Google Meet" },
    { id: "zoom", name: "Zoom" },
    { id: "webex", name: "Webex" },
  ];
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  let storedUser = window.localStorage.getItem("meetakerUser");
  if (!storedUser) {
    window.location.href = "/login";
  }
  let email = storedUser?.replaceAll("@", "-").replaceAll(".", "-");

  const [tabs, setTabs] = useState([
    { id: "recording", name: "Recordings", data: [] },
    { id: "transcript", name: "Transcriptions", data: [] },
    { id: "summary", name: "Summaries", data: [] },
  ]);
  const [selectedTab, setSelectedTab] = useState("recording");
  const [isRecording, setIsRecording] = useState(false);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [number, setNumber] = useState("");
  const [pin, setPin] = useState("");

  const fetchData = async () => {
    try {
      const config = {
        headers: { "content-type": `application/json` },
      };
      const data = {
        email,
      };
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/get-files`,
        data,
        config
      );
      const items = response.data?.data || [];
      if (items.length === 0) return;
      console.log(items);
      items.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      );
      let newTabs = [...tabs];
      tabs[0].data = items.filter((i) => i.fileName.includes("recording"));
      tabs[1].data = items.filter((i) => i.fileName.includes("transcript"));
      tabs[2].data = items.filter((i) => i.fileName.includes("summary"));
      setTabs(newTabs);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onTextChange = (event) => {
    switch (event.target.id) {
      case "number":
        setNumber(event.target.value.replaceAll(" ", "").replaceAll("-", ""));
        break;
      case "pin":
        setPin(event.target.value.replaceAll(" ", "").replaceAll("#", ""));
        break;
      default:
        break;
    }
  };

  const onSaveAudio = (blob) => {
    let uuid = uuidv4();
    let name = `${email}-local-recording-${uuid.substring(uuid.length - 6)}.webm`;
    let data = new FormData();
    let file = new File([blob], name);
    data.append("file", file);
    const config = {
      headers: { "content-type": `multipart/form-data` },
    };
    let newTabs = [...tabs];
    let recording = {
      id: name,
      fileName: name,
      createdAt: Date.now(),
      size: blob.size,
    };
    newTabs[0].data.unshift(recording);
    setTabs(newTabs);
    axios.post(`${BACKEND_ENDPOINT}/upload-file`, data, config);
  };

  const clickRecord = () => {
    if (isRecording) {
      recorderControls.stopRecording();
    } else {
      recorderControls.startRecording();
    }
    setIsRecording(!isRecording);
  };

  const onClickTab = (id) => {
    setSelectedTab(id);
  };

  const recorderControls = useAudioRecorder();

  const onJoin = async () => {
    let data = {
      platform: selectedPlatform.id,
      number,
      pin,
    };
    const headers = { "content-type": "application/json" };
    await axios
      .post(`${BACKEND_ENDPOINT}/dial-in`, data, headers)
      .then((res) => {
        let callId = res.data;
        console.log(callId)
        if (res.status === 201 && callId) {
          let uuid = uuidv4();
          data = {callId, fileName: `${email}-${selectedPlatform.id}-recording-${uuid.substring(uuid.length - 6)}.wav`}
          axios.post(`${BACKEND_ENDPOINT}/create_call_record`, data, headers)
          showNotification(
            "success",
            "Successfully submitted request to join the call! Please admit Meetaker.Ai to join the meeting."
          );
        } else {
          showNotification(
            "error",
            "Failed submitting request to join the call. Please try again later."
          );
        }
      })
      .catch((error) =>
        showNotification(
          "error",
          `Failed submitting request to join the call. Please try again later. ${error.toString()}`
        )
      );
    setOpen(false);
  };

  const showNotification = (type, text) => {
    setNotifications([...notifications, getNotification(type, text)]);
  };

  return (
    <>
      <Header />
      <div className="containerHome relative">
        {notifications}
        <Modal
          modalHeading="Join a Meeting"
          modalLabel=""
          primaryButtonText="Join"
          secondaryButtonText="Cancel"
          open={open}
          onRequestClose={() => setOpen(false)}
          onSecondarySubmit={() => setOpen(false)}
          onRequestSubmit={onJoin}
          primaryButtonDisabled={!selectedPlatform || !number || !pin}
        >
          <div className="marginBottom displayFlex">
            <div className="platforms">We support these meeting platforms:</div>
            <img src="/img/platforms.png" alt="plat" />
          </div>
          <Dropdown
            id="platform"
            label=""
            titleText="Meeting Platform"
            className="textWidth marginBottom"
            items={platforms}
            light={true}
            selectedItem={selectedPlatform}
            itemToString={(item) => (item ? item.name : "")}
            onChange={(event) => setSelectedPlatform(event.selectedItem)}
          />
          <div className="displayFlex evenSpacing marginBottom">
            <div className="textWidth">
              <TextInput
                id="number"
                labelText="Dial-in Number"
                light={true}
                placeholder="+112345678"
                value={number}
                onChange={onTextChange}
              />
            </div>
            <div className="textWidth">
              <TextInput
                id="pin"
                labelText="Meeting PIN"
                light={true}
                placeholder="8888888"
                value={pin}
                onChange={onTextChange}
              />
            </div>
          </div>
          <div>
            Note: Meetaker.Ai will join the meeting via dial-in from
            +1-888-903-4383. Please accpet it to join the meeting.
          </div>
        </Modal>
        <div className="hidden">
          <AudioRecorder
            onRecordingComplete={onSaveAudio}
            recorderControls={recorderControls}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadOnSavePress={false}
            downloadFileExtension="webm"
          />
        </div>
        <div className="tabs relative">
          <div className="record displayFlex evenSpacing">
            <Button
              size="sm"
              renderIcon={isRecording ? Stop : Play}
              kind={isRecording ? "danger" : "primary"}
              onClick={clickRecord}
            >
              {isRecording ? "Stop" : "Start"} recording
            </Button>
            <Button
              size="sm"
              kind="secondary"
              onClick={() => setOpen(true)}
              renderIcon={Add}
            >
              Join meeting
            </Button>
          </div>
          <Tabs>
            <TabList aria-label="List of tabs">
              {tabs.map((item) => (
                <Tab
                  key={item.id}
                  className="tabWidth"
                  onClick={() => onClickTab(item.id)}
                >
                  {item.name}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {tabs.map((item) => (
                <TabPanel key={item.id}>
                  {item.id === "recording" && isRecording && (
                    <>
                      <Loading className="center loading" withOverlay={false} />
                      <p className="recordingText">Recording in progress...</p>
                    </>
                  )}
                  {item.data.length === 0 && (
                    <>
                      <p className="emptyText">You don't have any files yet.</p>
                      <img
                        className="center"
                        src="/img/empty.png"
                        alt="empty"
                      />
                    </>
                  )}
                  <CardTable
                    data={item.data}
                    className="tableTop"
                    showCheckbox={true}
                    type={item.id}
                    refetch={fetchData}
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Home;
