import React from "react";
import "./Header.css";

class Header extends React.Component {
  handleClick = (path) => {
    window.location.href = path;
  };

  render() {
    return (
      <div className="headerPadding">
        <div className="displayFlex relative">
          <div className="logout displayFlex evenSpacing">
            <div className="haveAccount">My account</div>
            <div className="haveAccount">Log out</div>
          </div>
          <img src="/img/logo.png" alt="logo" width="5%" height="5%" />
          <p className="titleHome">Meetaker.Ai</p>
        </div>
        <hr className="hr" />
      </div>
    );
  }
}

export default Header;
