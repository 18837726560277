import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import "./documentCard.scss";

const FileCard = (props) => {
  function renderDocument() {
    let date = new Date(props.createdAt);
    return (
      <div className={"answer-item " + props.className}>
        <div className={props.showCheckbox ? "checkboxPadding" : ""}>
          <div className="answer-header">
            <div
              className="title-section"
              title="Click to view the document info"
            >
              <LinesEllipsis
                text={props.fileName}
                maxLine={1}
                component="h3"
                ellipsis="..."
                basedOn="words"
              />
              <p className="answer-experts">{`Created at: ${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return renderDocument();
};

export default FileCard;
