import React, { useState } from "react";
import "./login.scss";
import {
  Checkbox,
  TextInput,
  RadioButton,
  RadioButtonGroup,
} from "carbon-components-react";

function Login() {
  const [tab, setTab] = useState("home");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const onTextChange = (event) => {
    switch (event.target.id) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  const signup = () => {
    if (name && email && password) {
      setTab("terms");
      window.localStorage.setItem("meetakerUser", email);
    }
  };

  const login = () => {
    if (email && password) {
      window.localStorage.setItem("meetakerUser", email);
      window.location.href = "/";
    }
  };

  const subscribe = () => {
    if (email && password) {
      setTab("paid");
    }
  };

  return (
    <div className="displayFlex">
      <div className="left">
        <div className="displayFlex relative">
          <img src="/img/logo-graph.png" alt="logo-graph" />
          <div className="splashFontPos">
            <p className="splashFont">Record</p>
            <p className="splashFont">Transcribe</p>
            <p className="splashFont">Summarize</p>
          </div>
        </div>
      </div>
      <div className="right relative">
        <img
          className={tab === "payment" ? "hidden" : "center"}
          src="/img/logo.png"
          alt="logo"
        />
        <div className={tab === "trial" || tab === "paid" ? "" : "hidden"}>
          <p className="nameFont">Welcome to</p>
          <p className="nameFont secondaryTitle">Meetaker.Ai</p>
        </div>
        <div className={tab === "home" || tab === "terms" ? "" : "hidden"}>
          <p className="nameFont">Meetaker.Ai</p>
        </div>
        <div className={tab === "home" ? "" : "hidden"}>
          <p className="titleFont">
            Record video and audio meetings. Transcribe and summarize your
            recordings.
          </p>
        </div>
        <div className={tab === "trial" ? "" : "hidden"}>
          <p className="titleFont">
            Free trial is a limited version and doesn't include recording file
            download and share options. Your free trial will end on{" "}
            <span className="bold">September 20</span>. You can subscribe to
            full version at any time.
          </p>
        </div>
        <div className={tab === "paid" ? "" : "hidden"}>
          <p className="titleFont">
            Thank you for your payment! Your subscription includes Audio and
            Video recordings, transcription and summarization of the video and
            audio recordings, ability to download recordings on your device and
            share recordings, transcriptions and summaries with your team.
          </p>
        </div>
        <div className={tab === "terms" ? "" : "hidden"}>
          <p className="titleFont leftText">
            Subscribe to Meetaker. Ai and receive all benefits of the app
            including Audio and Video recording, transcription and of the video
            and audio recordings, ability to download recordings on your device
            and share recordings, transcriptions and summaries with your team.
          </p>
          <div className="titleFont leftText displayFlex">
            Or try limited version
            <div className="bold space">free for 7 days</div>.
          </div>
        </div>
        <div className={tab === "payment" ? "" : "hidden"}>
          <p className="loginFont">Billing information</p>
          <div className="displayFlex fields sides">
            <div>Payment Amount</div>
            <div>$40.00</div>
          </div>
          <div className="displayFlex evenSpacing fields">
            <RadioButtonGroup name="type" defaultSelected="credit">
              <RadioButton labelText="Credit Card" value="credit" />
              <RadioButton labelText="Debit Card" value="debit" />
            </RadioButtonGroup>
          </div>
          <TextInput
            id="number"
            labelText=""
            light={true}
            placeholder="Card Number"
            className="fields"
            // value={name}
            onChange={onTextChange}
          />
          <TextInput
            id="expiration"
            labelText=""
            light={true}
            placeholder="Expiration MM/YYYY"
            className="fields"
            // value={email}
            onChange={onTextChange}
          />
          <TextInput
            id="nameOnCard"
            labelText=""
            light={true}
            placeholder="Name on card"
            className="fields"
            // value={password}
            onChange={onTextChange}
          />
          <TextInput
            id="address1"
            labelText=""
            light={true}
            placeholder="Billing address line 1"
            className="fields"
            // value={password}
            onChange={onTextChange}
          />
          <TextInput
            id="address2"
            labelText=""
            light={true}
            placeholder="Billing address line 2"
            className="fields"
            // value={password}
            onChange={onTextChange}
          />
          <TextInput
            id="city"
            labelText=""
            light={true}
            placeholder="City"
            className="fields"
            // value={password}
            onChange={onTextChange}
          />
          <div className="displayFlex evenSpacing fields">
            <TextInput
              id="state"
              labelText=""
              light={true}
              placeholder="State/Province"
              // className="fields"
              // value={password}
              onChange={onTextChange}
            />
            <TextInput
              id="zip"
              labelText=""
              light={true}
              placeholder="Zip/Postal Code"
              // className="fields"
              // value={password}
              onChange={onTextChange}
            />
          </div>
          <TextInput
            id="country"
            labelText=""
            light={true}
            placeholder="Country"
            className="fields"
            // value={password}
            onChange={onTextChange}
          />
        </div>
        <div className={tab === "signup" || tab === "login" ? "" : "hidden"}>
          <p className="loginFont">
            {tab === "signup" ? "Create account" : "Login"}
          </p>
          <TextInput
            id="name"
            labelText=""
            light={true}
            placeholder="Full Name"
            className={tab === "signup" ? "fields" : "hidden"}
            value={name}
            onChange={onTextChange}
          />
          <TextInput
            id="email"
            labelText=""
            light={true}
            placeholder="Email"
            className="fields"
            value={email}
            onChange={onTextChange}
          />
          <TextInput
            type="password"
            id="password"
            labelText=""
            light={true}
            placeholder="Password"
            className="fields"
            value={password}
            onChange={onTextChange}
          />
        </div>

        <div className={tab === "home" ? "botPos" : "hidden"}>
          <div className="signup enabled" onClick={() => setTab("signup")}>
            Sign up
          </div>
          <p className="haveAccount" onClick={() => setTab("login")}>
            I HAVE AN ACCOUNT
          </p>
        </div>
        <div className={tab === "signup" ? "botPos" : "hidden"}>
          <div
            className={
              "signup " +
              (!(name && email && password) ? "disabled" : "enabled")
            }
            onClick={signup}
          >
            Create account
          </div>
          <p className="haveAccount" onClick={() => setTab("home")}>
            Back
          </p>
        </div>
        <div className={tab === "login" ? "botPos" : "hidden"}>
          <div
            className={
              "signup " + (!(email && password) ? "disabled" : "enabled")
            }
            onClick={login}
          >
            Login
          </div>
          <p className="haveAccount" onClick={() => setTab("home")}>
            Back
          </p>
        </div>

        <div className={tab === "terms" ? "botPos" : "hidden"}>
          <Checkbox
            id="terms"
            labelText="I have read and agree Meetaker.Ai to the terms."
            className="checkboxTerms"
            onChange={(_, { checked }) => setIsChecked(checked)}
          />
          <p className="terms">
            <span className="hyper">Terms of user</span>
            <span className="space">and</span>
            <span className="hyper space">Privacy policy</span>
          </p>
          <p
            className={"signup " + (isChecked ? "enabled" : "disabled")}
            onClick={() => {
              if (isChecked) setTab("payment");
            }}
          >
            Subscribe
          </p>
          <p
            className={
              "haveAccount secondary " + (isChecked ? "" : "wordDisabled")
            }
            onClick={() => {
              if (isChecked) setTab("trial");
            }}
          >
            7-Day Limited Free Trial
          </p>
          <p className="footer">
            (File download and share option not included)
          </p>
        </div>

        <div className={tab === "trial" ? "botPos" : "hidden"}>
          <div
            className="signup enabled"
            onClick={() => (window.location.href = "/")}
          >
            Start trial
          </div>
          <p className="haveAccount" onClick={() => setTab("payment")}>
            Subscribe to full version
          </p>
        </div>
        <div className={tab === "payment" ? "botPos" : "hidden"}>
          <div className="signup enabled" onClick={subscribe}>
            Subscribe
          </div>
          <p className="haveAccount" onClick={() => setTab("terms")}>
            Cancel
          </p>
        </div>
        <div className={tab === "paid" ? "botPos" : "hidden"}>
          <div
            className="signup enabled"
            onClick={() => (window.location.href = "/")}
          >
            Start
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
