import { createContext } from "react";
import { ToastNotification } from "carbon-components-react";

export const getNotification = (kind, subtitle) => {
  return (
    <ToastNotification
      key={Date.now()}
      kind={kind}
      title={kind === "success" ? "Success" : "Error"}
      subtitle={subtitle}
      className="notification"
      lowContrast={true}
      timeout={3000}
    />
  );
};

export const Context = createContext();

export const lowerCaseIncludes = (items, query) => {
  let keywords = query.trim().split(" ");
  for (let i = 0; i < keywords.length; i++) {
    if (
      !items.some((k) => k.toLowerCase().includes(keywords[i].toLowerCase()))
    ) {
      return false;
    }
  }
  return true;
};

export const getSize = (bytes) => {
  if(typeof bytes === "string") 
    bytes = parseInt(bytes);
  try {
    let num = Math.round(bytes / 1024 * 10) / 10;
    if (num > 1024) {
      num = Math.round((parseInt(num) / 1024) * 10) / 10;
      if (num > 1024) {
        num = Math.round((parseInt(num) / 1024) * 10) / 10;
        return `${num} GB`;
      }
      return `${num} MB`;
    }
    return `${num} KB`;
  } catch (e) {
    return "0";
  }
};
